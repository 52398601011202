import React, { useEffect } from 'react';
import { graphql } from "gatsby"

import { QueryFragments } from "../GraphQl/queryFragments" // eslint-disable-line

// Hooks, Querys & State
import { useAppState } from "../state/appState"

// Components
import { PageMeta } from "../components/pageMeta"
import BlockZone from "~blockZone"
import { PageBanner } from '../components/blockZone/blocks';

const PageTemplate = ({ data: { page } }) => {
  
  const { meta, banner, pageBlocks, darkHeader } = page || {};

  const { toggleHeaderLight, setHideFooter } = useAppState();

  useEffect(() => {
    if (darkHeader === true) {
      toggleHeaderLight(false);
    } else {
      toggleHeaderLight(true);
    }
  }, [toggleHeaderLight, darkHeader]);

  useEffect(() => {
    setHideFooter(false);
  }, [setHideFooter]);

  return (
    <>
      <PageMeta {...meta} />
      {banner && <PageBanner {...banner} />}
      {pageBlocks && <BlockZone {...pageBlocks} />}      
    </>
  );
}

export default PageTemplate

export const pageQuery = graphql`
  query standardPageQuery($slug: String!) {
    page: sanityPage(slug: { current: { eq: $slug } }) {
      banner {
        caption
        heading
        scrollText
        image {
          ...ImageWithPreview
        }
      }
      darkHeader
      meta {
        ...MetaCardFields
      }
      pageBlocks {
        ...BlockZone
      }
    }
  }
`;
